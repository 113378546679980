import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SubmitText from "./SubmitText";
import AcadamicFiles from "./AcadamicFiles";
import AcadamicInformation from "./AcadamicInformation";
import PersonalStatement from "./PersonalStatement";
import { FormikProvider, useFormik } from "formik";
import {
  useAddApplicantStageThree,
  useAddFiles,
  useFetchApplicantStageThree,
} from "../../../Hooks/Appplicant";
import getValidationSchemaStep2 from "../../../ValidationSchemas/Step2ValidationSchema";
import Reference from "../RegisterFormStep1/Reference";
import WorkExperience from "../RegisterFormStep1/WorkExperience";
import TranscriptMailingAddress from "./TranscriptMailingAddress";
import AttachCV from "./AttachCV";
import toast from "react-hot-toast";
import Loader from "../../Loader/Loader";

const RegisterFormStep2 = forwardRef(
  (
    {
      applicantId,
      applicationId,
      setApplicationStart,
      applingAs,
      applicationStart,
      setApplyingAs,
      activeStep,
      setActiveStep,
      setApplicationId,
      isView,
    },
    ref
  ) => {
    const {
      data: applicantStageThree,
      refetch: refetchStageThree,
      isLoading,
    } = useFetchApplicantStageThree(applicantId, applicationId);
    const {
      mutate: addApplicantStageThree,
      isLoading: isLoadingSubmitStageThree,
    } = useAddApplicantStageThree();
    const { mutate: addFiles, isLoading: isLoadingSubmitFiles } = useAddFiles();

    const [init, setInit] = useState({});
    const [moveNext, setMoveNext] = useState({ files: false, fields: false });

    useEffect(() => {
      const initialvalues = {
        isSaved: true,
        NextActiveStep: "",
        CurrentUniversityCountry:
          applingAs === 8
            ? "d96f12f0-332d-456a-b6b3-a7fca1851c0a"
            : applicantStageThree?.data?.stage2?.currentUniversityCountry || "",
        SchoolCountry:
          applingAs === 8
            ? ""
            : applicantStageThree?.data?.stage2?.schoolCountry || "",
        Other_SchoolCountry:
          applicantStageThree?.data?.stage2?.other_SchoolCountry || "",
        DiplomaType1: applicantStageThree?.data?.stage2?.diplomaType || "",
        DiplomaType2: applicantStageThree?.data?.stage2?.diplomaType1 || "",
        GraduationYear: applicantStageThree?.data?.stage2?.graduationYear
          ? applicantStageThree?.data?.stage2?.graduationYear?.substring(0, 10)
          : "",
        ListAdvancedCources:
          applicantStageThree?.data?.stage2?.listAdvancedCources || "",
        DiplomaFile:
          (applicantStageThree?.data?.diploma &&
            applicantStageThree?.data?.diploma[0]) ||
          "",
        ActivitiesNotEnrolled:
          applicantStageThree?.data?.stage2?.activitiesNotEnrolled || "",
        CurrentUniversityCountry2:
          applicantStageThree?.data?.stage2?.currentUniversityCountry2 || "",
        SchoolCountry2: applicantStageThree?.data?.stage2?.schoolCountry2 || "",
        other_SchoolCountry2:
          applicantStageThree?.data?.stage2?.other_SchoolCountry2 || "",
        applicantFiles:
          applicantStageThree?.data?.applicantTest &&
          applicantStageThree?.data?.applicantTest.length > 0
            ? applicantStageThree?.data?.applicantTest?.map((test) => ({
                testType: test?.testType?.toString(),
                academicDocument:
                  test?.files && test?.files[0] ? test?.files[0] : null,
                dateTaken: new Date(test.dateTaken)
                  ?.toISOString()
                  ?.split("T")[0],
                registrationNumber: test?.registrationNumber || "",
                totalScore: test?.totalScore || "",
              }))
            : [
                {
                  testType: "",
                  academicDocument: "",
                  dateTaken: "",
                  registrationNumber: "",
                  totalScore: "",
                },
              ],
        PersonalStatement:
          applicantStageThree?.data?.stage2?.personalStatement || "",
        applingAs: localStorage.getItem("applyingAs"),
        EmploymentStatus:
          applicantStageThree?.data?.applicantReferance?.employmentStatus || "",
        EmploymentSector:
          applicantStageThree?.data?.applicantReferance?.employmentSector || "",
        CompanyName:
          applicantStageThree?.data?.applicantReferance?.companyName || "",
        JobTitle: applicantStageThree?.data?.applicantReferance?.jobTitle || "",
        YearsOfExperience:
          applicantStageThree?.data?.applicantReferance?.yearsOfExperience ||
          "",
        ReferanceTitle:
          applicantStageThree?.data?.applicantReferance?.referanceTitle || "",
        ReferanceName:
          applicantStageThree?.data?.applicantReferance?.referanceName || "",
        ReferanceEmail:
          applicantStageThree?.data?.applicantReferance?.referanceEmail || "",
        KnowTheReferance:
          applicantStageThree?.data?.applicantReferance?.knowTheReferance || "",
        SendTheLetterRecomendation:
          applicantStageThree?.data?.applicantReferance
            ?.sendTheLetterRecomendation || "",
        ReadAndUnderstand:
          applicantStageThree?.data?.applicantReferance?.readAndUnderstand ||
          "",
        CV: applicantStageThree?.data?.cv || "",
        StudyBoardAdvisor:
          applicantStageThree?.data?.stage2?.studyBoardAdvisor || "",
        RegistrarEmail: applicantStageThree?.data?.stage2?.registrarEmail || "",
        RegistrarPhone: applicantStageThree?.data?.stage2?.registrarPhone || "",
        SchoolState: applicantStageThree?.data?.stage2?.schoolState || "",
      };
      setInit(initialvalues);
    }, [applicantStageThree]);

    const handleAddStageThree = (values) => {
      addApplicantStageThree(values, {
        onSuccess: (data) => {
          setMoveNext((prevPass) => ({
            ...prevPass,
            fields: true,
          }));
          setInit({});
        },
        onError: (error) => {
          window.scrollTo(0, 0);
          console.error("An error occurred:", error);
          toast.error("Something went wrong");
        },
      });
    };

    const handleAddFiles = (values) => {
      addFiles(values, {
        onSuccess: (data) => {
          setMoveNext((prevPass) => ({
            ...prevPass,
            files: true,
          }));
        },
        onError: (error) => {
          window.scrollTo(0, 0);
          console.error("An error occurred:", error);
          toast.error("Something in the files is wrong");
        },
      });
    };

    useEffect(() => {
      refetchStageThree();
    }, []);

    useEffect(() => {
      if (!applicationId) {
        const storedApplicationId = localStorage.getItem("applicationId");
        if (storedApplicationId) {
          setApplicationId(storedApplicationId);
        }
      }
    }, [applicationId]);

    const formik = useFormik({
      initialValues: init,
      validationSchema: getValidationSchemaStep2(applicationStart, applingAs),
      enableReinitialize: true,
      onSubmit: (values) => {
        const formData = new FormData();
        formData.append("ApplicantId", applicantId);
        formData.append("ApplicationId", localStorage.getItem("applicationId"));
        formData.append("IsSaved", formik.values.isSaved);
        formData.append("NextActiveStep", formik.values.NextActiveStep);
        if (values.DiplomaType1) {
          formData.append("DiplomaType", values.DiplomaType1);
        }
        if (values.DiplomaType2) {
          formData.append("DiplomaType1", values.DiplomaType2);
        }
        const fieldsToAppend = [
          "CurrentUniversityCountry",
          "SchoolCountry",
          "Other_SchoolCountry",
          "GraduationYear",
          "ListAdvancedCources",
          "ActivitiesNotEnrolled",
          "CurrentUniversityCountry2",
          "SchoolCountry2",
          "EmploymentSector",
          "EmploymentStatus",
          "JobTitle",
          "KnowTheReferance",
          "ReadAndUnderstand",
          "ReferanceEmail",
          "ReferanceTitle",
          "ReferanceName",
          "SendTheLetterRecomendation",
          "PersonalStatement",
          "CompanyName",
          "YearsOfExperience",
          "GraduationYear",
          "StudyBoardAdvisor",
          "RegistrarEmail",
          "RegistrarPhone",
          "SchoolState",
        ];

        fieldsToAppend.forEach((field) => {
          formData.append(field, values[field]);
        });

        const hasStageThreeData = Object.values(fieldsToAppend).some(
          (field) => !!values[field]
        );

        const fileToAppend = ["CV", "DiplomaFile"];
        const fetchedNames = {
          CV: "cv",
          DiplomaFile: "diploma",
        };
        fileToAppend.forEach((key) => {
          const fileValue = values[key];
          const fileName = fileValue?.fileName;

          if (fileName) {
            return;
          }

          const fetchedFile =
            key == "DiplomaFile" &&
            applicantStageThree?.data?.diploma?.length > 0
              ? applicantStageThree?.data?.[fetchedNames[key]][0]
              : null;

          if ((!fileValue || fileValue === "") && !fetchedFile) {
            return;
          }
          if (!fileName && !fetchedFile && fileValue) {
            formData.append(`${key}.File`, fileValue);
            formData.append(`${key}.StatusId`, 0);
          }
          if (!fileName && fetchedFile && fileValue) {
            formData.append(`${key}.File`, fileValue);
            formData.append(`${key}.StatusId`, 1);
          }
          if (fetchedFile && !fileValue) {
            formData.append(`${key}.StatusId`, 2);
          }
        });
        /*********************Appliacnt files post */

        const formDataFiles = new FormData();
        values.applicantFiles.forEach((file, index) => {
          // Append common applicant file data
          formDataFiles.append(
            `applicantFiles[${index}].applicantId`,
            applicantId
          );
          formDataFiles.append(
            `applicantFiles[${index}].applicationId`,
            applicationId
          );
          formDataFiles.append(
            `applicantFiles[${index}].testType`,
            file.testType
          );
          formDataFiles.append(
            `applicantFiles[${index}].dateTaken`,
            file.dateTaken
          );
          formDataFiles.append(
            `applicantFiles[${index}].registrationNumber`,
            file.registrationNumber
          );
          formDataFiles.append(
            `applicantFiles[${index}].totalScore`,
            file.totalScore
          );

          const fileValue = file.academicDocument;
          const fileName = fileValue?.fileName;
          const fetchedFile =
            applicantStageThree?.data?.applicantFiles?.[index]?.academicDocument
              ?.file;

          if (!fileName && !fetchedFile && fileValue) {
            formDataFiles.append(
              `applicantFiles[${index}].academicDocument.file`,
              fileValue
            );
            formDataFiles.append(
              `applicantFiles[${index}].academicDocument.statusId`,
              1
            );
          }
          if (!fileName && fetchedFile && fileValue) {
            formDataFiles.append(
              `applicantFiles[${index}].academicDocument.file`,
              fileValue
            );
            formDataFiles.append(
              `applicantFiles[${index}].academicDocument.statusId`,
              1
            );
          }
          if (fetchedFile && !fileValue) {
            formDataFiles.append(
              `applicantFiles[${index}].academicDocument.statusId`,
              2
            );
          }
        });
        handleAddFiles(formDataFiles);

        if (hasStageThreeData) {
          handleAddStageThree(formData);
        }
      },
    });

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formik.submitForm();
      },
    }));

    useEffect(() => {
      ref.current = formik;
    }, [ref, formik]);

    useEffect(() => {
      const hasFilledFile = formik.values.applicantFiles?.some((file) => {
        return Object.values(file).some((value) => value !== "");
      });

      if (hasFilledFile) {
        if (moveNext.fields && moveNext.files) {
          setActiveStep((prev) => prev + 1);
          window.scrollTo(0, 0);
        }
      } else {
        if (moveNext.fields) {
          setActiveStep((prev) => prev + 1);
          window.scrollTo(0, 0);
        }
      }
      const timeout = setTimeout(() => {
        setMoveNext((prevPass) => ({
          ...prevPass,
          fields: false,
          files: false,
        }));
      }, 1000);

      return () => clearTimeout(timeout);
    }, [moveNext.fields, moveNext.files, formik.values.applicantFiles]);

    if (isLoading || isLoadingSubmitFiles || isLoadingSubmitStageThree) {
      return <Loader width='100%' />;
    }

    return (
      <div className='form-subcontainer'>
        <FormikProvider value={formik} innerRef={ref}>
          <SubmitText />
          <AcadamicInformation isView={isView} data={applicantStageThree} />
          {applingAs == 6 || applingAs == 8 ? (
            <TranscriptMailingAddress isView={isView} />
          ) : (
            ""
          )}
          {applingAs !== 8 ? (
            <>
              <AcadamicFiles
                isView={isView}
                applicationStart={applicationStart}
              />
            </>
          ) : (
            ""
          )}

          {applicationStart === 1 || applingAs == 8 ? (
            <>
              <WorkExperience isView={isView} />
              <Reference isView={isView} applingAs={applingAs} />
            </>
          ) : (
            ""
          )}

          {applingAs === 8 ? (
            <>
              <AttachCV isView={isView} />
            </>
          ) : (
            ""
          )}

          {/* {applingAs !== 0 && applingAs !== 7 ? (
            <PersonalStatement isView={isView} />
          ) : (
            ""
          )} */}
          <PersonalStatement isView={isView} />
        </FormikProvider>
      </div>
    );
  }
);

export default RegisterFormStep2;
